import {
  Text,
  VStack,
  Box,
  Icon,
  HStack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
} from '@chakra-ui/react';
import { TiArrowDownThick } from 'react-icons/ti';
import { FaHourglassHalf, FaClipboard } from 'react-icons/fa';
import { GiFireworkRocket } from 'react-icons/gi';

function RenderProjectTimeline() {
  const timelineTexts = [
    {
      time: 'September 2022',
      title: 'Idea generation & project planning',
      description:
        'Kicking things off with brainstorming and mapping out our plan.',
      status: 'Completed',
    },
    {
      time: 'October 2022 to 30 June 2024',
      title: 'Smart contracts & UI development & testing',
      description:
        'Building and fine-tuning our smart contracts and user interface.',
      status: 'Completed',
    },

    {
      time: '01 July 2024 to 18 December 2024',
      title: 'UI Integration with Polygon Amoy Testnet',
      description:
        'Connecting our UI to the Polygon Amoy Testnet for testing, while continuously fine-tuning our smart contracts and user interface.',
      status: 'In Progress',
    },
    {
      time: '18 December 2024 to 18 January 2025',
      title: 'UI Integration with Polygon Mainnet',
      description:
        'Deploying our smart contracts on the Polygon Mainnet and connecting our user interface to the Polygon Mainnet. Preparing both frontend and backend for public use.',
      status: 'Not Started',
    },

    {
      time: '18 January 2025 and onward',
      title: 'Continuous monitoring, maintenance, and improvement',
      description:
        'Keeping a close watch, addressing bugs, and enhancing overall functionality based on feedback from users.',
      status: 'Not Started',
    },
  ];
  const bgGradients = [
    'linear-gradient(132deg, rgb(2, 28, 122) 0.00%, rgb(36, 83, 255) 100.00%)',
  ];

  return (
    <VStack spacing="10px">
      <Box>
        <Text
          color="green.400"
          fontSize={[26, 36, 47]}
          style={{ fontFamily: 'Lobster' }}
        >
          Project Timeline
        </Text>
      </Box>
      {timelineTexts.map((timelineText, i) => (
        <VStack key={i}>
          <Icon as={TiArrowDownThick} boxSize="74px" color="green.300" />

          <Card
            bgGradient={bgGradients[0]}
            boxShadow="2xl"
            p="6"
            rounded="lg"
            maxW="lg"
          >
            <CardHeader>
              <Heading size="md">
                {' '}
                <Text color="white">{timelineText.title}</Text>
              </Heading>
            </CardHeader>
            <CardBody>
              <Text color="white">{timelineText.description}</Text>
            </CardBody>
            <CardFooter>
              <HStack spacing={8}>
                <Box>
                  <Text color="white">{timelineText.time}</Text>
                </Box>
                <Box>
                  {timelineText.status === 'In Progress' && (
                    <FaHourglassHalf w={8} h={8} color="green" />
                  )}
                  {timelineText.status === 'Completed' && (
                    <GiFireworkRocket boxSize="36px" color="yellow" />
                  )}
                  {timelineText.status === 'Not Started' && (
                    <FaClipboard boxSize="28px" color="white" />
                  )}
                </Box>
                <Box>
                  <Text fontSize="sm" color="white">
                    {timelineText.status}
                  </Text>
                </Box>
              </HStack>
            </CardFooter>
          </Card>
        </VStack>
      ))}
    </VStack>
  );
}

export default RenderProjectTimeline;
