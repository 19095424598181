import { Text, VStack, Box, ListItem, UnorderedList } from '@chakra-ui/react';
import ValleyGif from '../../../resources/images/valley/magicnftvalley.gif';
import NftGif from '../../../resources/images/valley/nfts.gif';
import FutureGif from '../../../resources/images/valley/future.gif';
const RenderAboutValley = () => {
  return (
    <VStack spacing={6}>
      <Box>
        <Text
          color="green.400"
          fontSize={[26, 36, 47]}
          style={{ fontFamily: 'Lobster' }}
        >
          Magic NFT Valley
        </Text>
      </Box>

      <Box>
        <Text fontSize="2xl">
          Welcome to Magic NFT Valley, a fully decentralized hub for NFT sales
          and auctions, with a special focus on Web3 gaming NFTs.
        </Text>
      </Box>
      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          Our Mission
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          At Magic NFT Valley, we aim to revolutionize the NFT landscape by
          providing seamless integration and a dynamic marketplace and auction
          house for game owners, artists, and players.
        </Text>
      </Box>
      <img
        src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1719625815/magicnftvalley_budken.gif"
        alt="Magic NFT Valley"
      />
      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          Functions
        </Text>
      </Box>
      <Box>
        <UnorderedList spacing={3}>
          <ListItem>
            <Text fontSize="2xl">
              <Text as="b">Blockchain Integration for Game Owners</Text>: We
              simplify the integration of blockchain technology for game owners,
              particularly small games, by handling the complexities so they can
              focus on game development.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="2xl">
              <Text as="b">Art Integration for Artists</Text>: We assist artists
              in incorporating their creations into games as NFTs, bridging the
              gap between art and interactive digital experiences.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="2xl">
              <Text as="b">Enhanced Gaming Experience for Players</Text>: We
              strive to enhance the gaming experience for players by
              incorporating innovative NFT solutions.
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize="2xl">
              <Text as="b">General NFT Marketplace and Auction House</Text>: In
              addition to our specialized services, we serve as a comprehensive
              NFT marketplace and auction platform.
            </Text>
          </ListItem>
        </UnorderedList>
      </Box>
      {/* 
      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          NFT Versatility
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          Our Marketplace and Auction House support a wide range of NFTs,
          including images, audio files, videos, 3D models, 3D environment
          textures, and more.
        </Text>
      </Box>
      <img src={NftGif} alt="NFTs" /> */}

      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          Our Commitment
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          At Magic NFT Valley, we are committed to fostering innovation and
          providing a thriving ecosystem for the NFT community. Join us and be
          part of the future of gaming and digital art.
        </Text>
      </Box>
      <img src={FutureGif} alt="Future" />

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
          @import url('https://fonts.googleapis.com/css?family=Roboto');
        `}
      </style>
    </VStack>
  );
};

export default RenderAboutValley;
