import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Wrap,
  WrapItem,
  Stack,
  VStack,
  Image,
  Grid,
  theme,
  HStack,
  RadioGroup,
  Radio,
  Link,
  Badge,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Container,
  Alert,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import AllTokensOwnedBy from '../../../components/views/NFTs/AllTokensOwnedBy';
import AllTokensCreatedBy from '../../../components/views/NFTs/AllTokensCreatedBy';
import AllCollectionsCreatedBy from '../../../components/views/NFTs/AllCollectionsCreatedBy';
// import AllMarketplaceItemsCreatedBy from '../../../components/views/NFTs/marketplace/AllMarketplaceItemsCreatedBy';
// import AllAuctionsCreatedBy from '../../../components/views/NFTs/auctions/AllAuctionsCreatedBy';
import AllValleyItemsCreatedBy from '../../../components/views/NFTs/AllValleyItemsCreatedBy';
import ViewToken from '../../../components/views/NFTs/elements/ViewToken';
import AllGamesOwnedBy from '../../../components/views/NFTs/AllGamesOwnedBy';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
// import UserMembership from '../../../components/views/NFTs/UserMembership';
// import Campaign from '../../../components/views/misc/Campaign';
const User = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const [value, setValue] = useState('2');

  const DisplayList = () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    if (value === '2') {
      return (
        <>
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              <Tab>ERC721 from MagicNFT contract</Tab>
              <Tab>ERC1155 from MagicNFTerc1155 contract</Tab>
              <Tab>Tokens from Other Contracts</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <AllTokensOwnedBy provider={provider} addr={address} />
              </TabPanel>
              <TabPanel>
                <ViewToken useCase="MagicNFTerc1155" user={address} />
              </TabPanel>
              <TabPanel>
                <ViewToken useCase="otherContracts" user={address} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      );
    } else if (value === '3') {
      return (
        <>
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              <Tab>ERC721</Tab>
              <Tab>ERC1155</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <AllTokensCreatedBy
                  provider={provider}
                  addr={address}
                  isErc1155={false}
                />
              </TabPanel>
              <TabPanel>
                <AllTokensCreatedBy
                  provider={provider}
                  addr={address}
                  isErc1155={true}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      );
    } else if (value === '4') {
      return <AllCollectionsCreatedBy provider={provider} addr={address} />;
    } else if (value === '5') {
      return (
        <>
          <Tabs variant="soft-rounded" colorScheme="green" align="center">
            <TabList>
              <Tab>ERC721</Tab>
              <Tab>ERC1155</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <AllValleyItemsCreatedBy
                  provider={provider}
                  isAuction={false}
                  isERC1155={false}
                  addr={address}
                />
              </TabPanel>
              <TabPanel>
                <AllValleyItemsCreatedBy
                  provider={provider}
                  isAuction={false}
                  isERC1155={true}
                  addr={address}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      );
    } else if (value === '6') {
      return (
        <AllValleyItemsCreatedBy
          provider={provider}
          isAuction={true}
          isERC1155={false}
          addr={address}
        />
      );
      // return <AllAuctionsCreatedBy addr={address} />;
    } else if (value === '7') {
      return <AllGamesOwnedBy provider={provider} addr={address} />;
    } else if (value === '8') {
      return (
        <VStack>
          <Link
            color="teal.500"
            as={ReactLink}
            to={`/mypage/artists/${address}`}
          >
            <Button variant="link">My Artist Page</Button>
          </Link>
          <Link color="teal.500" as={ReactLink} to={`/mypage/shops/${address}`}>
            <Button variant="link">My Shop Page</Button>
          </Link>
          <Link color="teal.500" as={ReactLink} to={'/allmymagicflowers'}>
            <Button variant="link">My Magic Flowers</Button>
          </Link>
        </VStack>
      );
    }
  };

  return (
    <VStack>
      <Box textAlign="center">
        <Box style={{ marginBottom: '30px' }}>
          <Text
            color="green.400"
            fontSize={[36, 42, 68]}
            style={{ fontFamily: 'Lobster' }}
          >
            Private Haven
          </Text>
          <Text color="green.400">{address}</Text>
        </Box>
        <HomeIcon />

        <Container>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Dashboard
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box>
                  <RadioGroup onChange={setValue} value={value}>
                    <Stack>

                      <Radio value="2" colorScheme="green">
                        All Tokens I Own
                      </Radio>
                      <Radio value="3" colorScheme="green">
                        All Tokens I Created
                      </Radio>
                      <Radio value="4" colorScheme="green">
                        All Collections I Created
                      </Radio>

                      <Radio value="5" colorScheme="green">
                        All Marketplace Sales I Created
                      </Radio>
                      <Radio value="6" colorScheme="green">
                        All Auctions I Created
                      </Radio>

                      <Radio value="7" colorScheme="green">
                        All Games I Owned
                      </Radio>
                      <Radio value="8" colorScheme="green">
                        My Pages( Shop / Artist / Magic Flowers )
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Container>
        <DisplayList />
      </Box>
    </VStack>
  );
};

export default User;
