import { Link as ReactLink } from 'react-router-dom';
import {
  Link,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  IconButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

function NavMenu({ isMagicflowerIdRoute }) {
  const _style = isMagicflowerIdRoute
    ? {}
    : {
        position: 'absolute',
        top: '0%',
        left: '0%',
      };
  return (
    <div style={_style}>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon w={8} h={8} color="green.300" />}
          variant="outline"
        ></MenuButton>
        <MenuList>
          <MenuItem>
            <Link as={ReactLink} to="/">
              Home
            </Link>
          </MenuItem>
          <MenuItem>
            <Link as={ReactLink} to="/about">
              About
            </Link>
          </MenuItem>
          <MenuItem>
            <Link as={ReactLink} to="/marketplace">
              Marketplace
            </Link>
          </MenuItem>
          <MenuItem>
            <Link as={ReactLink} to="/auctions">
              Auction House
            </Link>
          </MenuItem>
          <MenuItem>
            <Link as={ReactLink} to="/dreamstudio">
              Dream Studio
            </Link>
          </MenuItem>

          <MenuItem>
            <Link as={ReactLink} to="/gamenfts">
              Game NFTs
            </Link>
          </MenuItem>
          <MenuItem>
            <Link as={ReactLink} to="/gamelist">
              Game Showcase
            </Link>
          </MenuItem>

          <MenuItem>
            <Link as={ReactLink} to="/user">
              User
            </Link>
          </MenuItem>
          <MenuItem>
            <Link as={ReactLink} to="/faq">
              FAQ
            </Link>
          </MenuItem>
          <MenuItem>
            <Link as={ReactLink} to="/termsandconditions">
              Terms and Conditions
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link as={ReactLink} to="/membership">
              Membership
            </Link>
          </MenuItem> */}
          <MenuItem>
            <Link as={ReactLink} to="/jsoncreator">
              JSON Creator
            </Link>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}

export default NavMenu;
